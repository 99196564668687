import { formatLocale } from 'd3-format';
import { NumberFormatter } from '@superset-ui/core';

// French locale format for currencies
// See https://observablehq.com/@d3/d3-format
const locale = formatLocale({
  decimal: ',',
  thousands: ' ',
  grouping: [3],
  currency: ['', '€'],
});

export const swileEuroFormatter = (format: string) => {
  const formatEuro = locale.format(format); // Use $ as placeholder for currency (€).
  return new NumberFormatter({
    id: `EURO_${format}`,
    label: `EURO_${format}`,
    formatFunc: value => formatEuro(value),
  });
};
